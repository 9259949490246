import actions from "./actions";

const { USER_TYPE, LOGIN_SUCCESS, FORGOT_PASSWORD, SECTORS_LIST, PROFESSIONS_LIST, JOBS_TYPES_LIST, NATIONALITIES_LIST, LOCATIONS_LIST, WORKING_PERMISSIONS_LIST, AGENCIS_LIST, REGISTER_AGENCY, ACCOUNT_DISABLED, REACTIVATE_ACCOUNT } = actions

const initialState = {
    userType: window.localStorage.getItem('userType'),
    login: null,
    forgetPassword: null,
    sectors: null,
    professions: null,
    jobsTypes: null,
    nationalities: null,
    locations: null,
    workingPermission: null,
    agenceList: null,
    disabled: null,
    isEnabled: false,
    registerAgency: null
}

const authReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case USER_TYPE:
            return {
                ...state,
                userType: data
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                login: data
            }
        case ACCOUNT_DISABLED:
            return {
                ...state,
                disabled: data
            }
        case FORGOT_PASSWORD:
            return {
                ...state,
                forgetPassword: data
            }
        case SECTORS_LIST:
            return {
                ...state,
                sectors: data
            }
        case PROFESSIONS_LIST:
            return {
                ...state,
                professions: data
            }
        case LOCATIONS_LIST:
            return {
                ...state,
                locations: data
            }
        case JOBS_TYPES_LIST:
            return {
                ...state,
                jobsTypes: data
            }
        case NATIONALITIES_LIST:
            return {
                ...state,
                nationalities: data
            }
        case WORKING_PERMISSIONS_LIST:
            return {
                ...state,
                workingPermission: data
            }
        case AGENCIS_LIST:
            return {
                ...state,
                agenceList: data
            }
        case REACTIVATE_ACCOUNT:
            return {
                ...state,
                isEnabled: data
            }
        case REGISTER_AGENCY:
            return {
                ...state,
                registerAgency: data
            }
        default:
            return state;
    }
}

export default authReducer;