const actions = {
    ALL_CANDIDATURE: "ALL_CANDIDATURE",
    ALL_PROPOSED_CANDIDATE: "ALL_PROPOSED_CANDIDATE",

    getAllCandidature: data => {
        return {
            type: actions.ALL_CANDIDATURE,
            data
        }
    },

    getAllProposedCandidate: data => {
        return {
            type: actions.ALL_PROPOSED_CANDIDATE,
            data
        }
    }
}

export default actions