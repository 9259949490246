import actions from "./actions";

const {
  SUCCURSALE,
  POST_TAGS,
  TAGS,
  LIST_MISSIONS,
  LIST_MISSIONS_ACTION,
  LIST_FOLDER,
  NEW_LINK,
  NEW_MISSION,
  NEW_FAVORIS,
  NEW_FOLDERS,
  NEW_INTERST,
  ARCHIVAGE,
  DISABLE,
  LIST_EMAIL,
  ACTIVITY,
  COMMENT,
  ADD_COMMENT,
  EDIT_FAVORIS,
  CHECK_EMAIL
} = actions;

const initialState = {
  agenceList: null,
  missionAction: null,
  listFolders: null,
  newMission: null,
  newLink: null,
  newFolders: null,
  newFavoris: null,
  newInterest: null,
  archiver: null,
  disabled: null,
  tags: null,
  postTag: null,
  listSuccursale: null,
  email: null,
  activity: null,
  editComment: null,
  postComment: null,
  favoris: null,
  week: null
};

const agencyMissionsReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case CHECK_EMAIL:
      return {
        ...state,
        week: data,
      };
    case EDIT_FAVORIS:
      return {
        ...state,
        favoris: data,
      };
    case ADD_COMMENT:
      return {
        ...state,
        postComment: data,
      };
    case COMMENT:
      return {
        ...state,
        editComment: data,
      };
    case ACTIVITY:
      return {
        ...state,
        activity: data,
      };
    case LIST_EMAIL:
      return {
        ...state,
        email: data,
      };
    case SUCCURSALE:
      return {
        ...state,
        listSuccursale: data,
      };
    case POST_TAGS:
      return {
        ...state,
        postTag: data,
      };
    case LIST_MISSIONS:
      return {
        ...state,
        agenceList: data,
      };
    case LIST_MISSIONS_ACTION:
      return {
        ...state,
        missionAction: data,
      };
    case LIST_FOLDER:
      return {
        ...state,
        listFolders: data,
      };
    case NEW_MISSION:
      return {
        ...state,
        newMission: data,
      };
    case NEW_LINK:
      return {
        ...state,
        newLink: data,
      };
    case NEW_FAVORIS:
      return {
        ...state,
        newFavoris: data,
      };
    case NEW_FOLDERS:
      return {
        ...state,
        newFolders: data,
      };
    case NEW_INTERST:
      return {
        ...state,
        newInterest: data,
      };
    case ARCHIVAGE:
      return {
        ...state,
        archiver: data,
      };
    case DISABLE:
      return {
        ...state,
        disabled: data,
      };
    case TAGS:
      return {
        ...state,
        tags: data,
      };
    default:
      return state;
  }
};

export default agencyMissionsReducer;
